<template>
  <div class="indexother">
      <div class="minw" v-if="!$route.meta.isShowrouterboxFlag">
           <div class="routerbox"  >
                <div class="routerbox_Item" @click="gogogo(item)" :class="{ active :item.menu == menu }" v-for="(item,index) in routerDiv" :key="index">{{ item.name}}</div>
            </div>
      </div>
      <keep-alive>
          <router-view v-if="$route.meta.keepAlive"></router-view>
      </keep-alive>
        <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>

<script>
import { REQlevelscore } from "@/api/homeIndex.js";
export default {
  name: "indexother",
  data() {
    return {
        routerDiv:[
                {name:'查学校',routerpath:'/checkschools',menu:1},
                {name:'查专业',routerpath:'/professionalinvestigation',menu:2},
                {name:'查位次',routerpath:'/Onebyone',menu:3},
                {name:'查职业',routerpath:'/occupation',menu:4},
                {name:'招生计划',routerpath:'/students',menu:5},
                {name:'提前批',routerpath:'/advance',menu:6},
                {name:'学科评估',routerpath:'/curriculumJudgement',menu:7},
                {name:'批次线',routerpath:'/Batchline',menu:8},
        ],
        menu:'',
    };
  },
  created() {

  },
  mounted(){
    this.routerpath = this.$route.meta.menu
  },
  watch: {
    $route: {
      deep: true,
      immediate: true,
      handler (newvalue,oldvalue) {
          this.menu = newvalue.meta.menu
      }
    }
  },
  methods: {
      gogogo(item){
          this.menu = item.menu;
          this.$router.push({
            path:item.routerpath
          })
      }


  },
};
</script >

<style lang="scss" scoped>
.indexother{
    .routerbox{
          margin: 15px 0;
          display: flex;
          align-items: center;
          justify-content: space-between;
            // dotted
          border-bottom: 2px solid #D9D9D9;
          .routerbox_Item{
              color: #333333;
              font-size: 16px;
              padding: 8px 0;
              cursor: pointer;
              flex:1;
              text-align: center;
              height: 30px;
              line-height: 30px;
          }
          .routerbox_Item:hover{
            color:#12B098;
          }
          .active{
            color: white;
            background: #12B098;
            // border-bottom: 5px solid #12B098;
          }
          .active:hover{
            color: white;
          }
    }
}
 </style>
